'use client';

import { useState } from 'react';

import { useLocalStorage } from '@squadnet/frontend-shared-context';
import { Button, Link, TextSmall } from '@squadnet/frontend-web-ui';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';

export const AcceptCookie = () => {
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  useLocalStorage<boolean>('@squadnet/accept-cookie', isAccepted, setIsAccepted);
  const analytics = useAnalytics();

  const accept = () => {
    analytics.track('AcceptCookieClicked', {
      cookieEnabled: true,
    });
    setIsAccepted(true);
  };

  if (isAccepted) return null;

  return (
    <div className='fixed inset-x-0 bottom-0 z-10 flex min-h-[48px] flex-col items-center justify-center bg-[#000]/80 p-3'>
      <TextSmall variant='inverted'>
        This site uses cookies to provide you with a great user experience. By using Squadnet, you accept our{' '}
        <Link href='/privacy-policy' className='!text-white-text underline' target='_blank'>
          use of cookies
        </Link>
        .
      </TextSmall>
      <div className='flex pt-3'>
        <Button variant='grey' className='mx-2 w-[120px]' size='sm' onClick={accept}>
          Reject
        </Button>
        <Button variant='primary' className='mx-2 w-[120px]' size='sm' onClick={accept}>
          Accept
        </Button>
      </div>
    </div>
  );
};

export default AcceptCookie;
