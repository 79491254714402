'use client';

import dynamic from 'next/dynamic';

const Auth = dynamic(() => import('./Auth'), {
  ssr: false,
  loading: () => null,
});
const Referral = dynamic(() => import('./Referral'), {
  ssr: false,
});
const PageTracking = dynamic(() => import('./PageTracking'), {
  ssr: false,
});

export const Effects = () => {
  return (
    <>
      <Auth />
      <Referral />
      <PageTracking />
    </>
  );
};
