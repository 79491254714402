'use client';

import dynamic from 'next/dynamic';

const Amplify = dynamic(() => import('./Amplify'));
const Branch = dynamic(() => import('./Branch'), { ssr: false });

export const Vendors = () => {
  return (
    <>
      <Amplify />
      <Branch />
    </>
  );
};
